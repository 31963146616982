import React from "react"
import kebabCase from "lodash/kebabCase"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import "./index.scss"

const Tags = ({ Tagsdata, title }) => {
  return (
    <div id="tagsPages" className="tagsPages py-5">
      <a className="back py-4" href="/blog/">
        <span>Return to Blog List</span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
        </svg>
      </a>
      <Helmet title={title} />
      <div>
        <h1>All Tags</h1>
        {Tagsdata?.map(([key, values]) => {
          return (
            <div className="taglist" key={key}>
              <p className="key">{key}</p>
              {values?.map(tag => (
                <Link
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}
                  className="px-2"
                >
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tags
