import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout/t1/layout"
import Tags from "../components/tags"

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const getFilteredArr = arr => {
    const map = new Map()
    arr
      .sort((a, b) => a.fieldValue?.localeCompare(b.fieldValue))
      .forEach(str =>
        !map.has(str.fieldValue[0]?.toUpperCase())
          ? map.set(str.fieldValue[0]?.toUpperCase(), [str])
          : map.get(str.fieldValue[0]?.toUpperCase()).push(str)
      )
    return [...map]
  }
  const Tagsdata = getFilteredArr(group)

  return (
    <Layout>
      <Tags Tagsdata={Tagsdata} title={title} />
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
